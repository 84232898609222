// src/core/zone-helpers.mjs
import { NgZone } from "@angular/core";
function isNgZone(zone) {
  return zone instanceof NgZone;
}

// src/core/tick-scheduler.mjs
import { inject, Injectable, NgZone as NgZone2 } from "@angular/core";
import * as i0 from "@angular/core";
var TickScheduler = class _TickScheduler {
  static {
    this.ɵfac = function _TickScheduler_Factory(t) {
      return new (t || _TickScheduler)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: _TickScheduler,
      factory: () => (() => {
        const zone = inject(NgZone2);
        return isNgZone(zone) ? new NoopTickScheduler() : inject(AnimationFrameTickScheduler);
      })(),
      providedIn: "root"
    });
  }
};
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TickScheduler, [{
    type: Injectable,
    args: [{
      providedIn: "root",
      useFactory: () => {
        const zone = inject(NgZone2);
        return isNgZone(zone) ? new NoopTickScheduler() : inject(AnimationFrameTickScheduler);
      }
    }]
  }], null, null);
})();
var AnimationFrameTickScheduler = class _AnimationFrameTickScheduler extends TickScheduler {
  constructor(appRef) {
    super();
    this.appRef = appRef;
    this.isScheduled = false;
  }
  schedule() {
    if (!this.isScheduled) {
      this.isScheduled = true;
      requestAnimationFrame(() => {
        this.appRef.tick();
        this.isScheduled = false;
      });
    }
  }
  static {
    this.ɵfac = function _AnimationFrameTickScheduler_Factory(t) {
      return new (t || _AnimationFrameTickScheduler)(i0.ɵɵinject(i0.ApplicationRef));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: _AnimationFrameTickScheduler,
      factory: _AnimationFrameTickScheduler.ɵfac,
      providedIn: "root"
    });
  }
};
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AnimationFrameTickScheduler, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: i0.ApplicationRef
  }], null);
})();
var NoopTickScheduler = class extends TickScheduler {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  schedule() {}
};

// src/core/render-scheduler.mjs
import { ChangeDetectorRef, inject as inject2, Injectable as Injectable2 } from "@angular/core";
import * as i02 from "@angular/core";
var RenderScheduler = class _RenderScheduler {
  constructor(cdRef, tickScheduler) {
    this.cdRef = cdRef;
    this.tickScheduler = tickScheduler;
  }
  /**
   * Marks component and its ancestors as dirty.
   * It also schedules a new change detection cycle in zone-less mode.
   */
  schedule() {
    this.cdRef.markForCheck();
    this.tickScheduler.schedule();
  }
  static {
    this.ɵfac = function _RenderScheduler_Factory(t) {
      return new (t || _RenderScheduler)(i0.ɵɵinject(i02.ChangeDetectorRef), i0.ɵɵinject(TickScheduler));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: _RenderScheduler,
      factory: _RenderScheduler.ɵfac
    });
  }
};
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RenderScheduler, [{
    type: Injectable2
  }], () => [{
    type: i02.ChangeDetectorRef
  }, {
    type: TickScheduler
  }], null);
})();
function createRenderScheduler() {
  return new RenderScheduler(inject2(ChangeDetectorRef), inject2(TickScheduler));
}

// src/core/render-event/handlers.mjs
function combineRenderEventHandlers(handlers) {
  return event => handlers[event.type]?.(event);
}

// src/core/potential-observable.mjs
import { combineLatest, from, isObservable, Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
function fromPotentialObservable(potentialObservable) {
  if (isObservable(potentialObservable)) {
    return potentialObservable;
  }
  if (isObservableDictionary(potentialObservable)) {
    return combineLatest(toDistinctObsDictionary(potentialObservable));
  }
  if (isPromiseLike(potentialObservable)) {
    return from(potentialObservable);
  }
  return new Observable(subscriber => {
    subscriber.next(potentialObservable);
  });
}
function isPromiseLike(value) {
  return typeof value?.then === "function";
}
function isObservableDictionary(value) {
  return isDictionary(value) && Object.keys(value).length > 0 && Object.values(value).every(isObservable);
}
function isDictionary(value) {
  return !!value && typeof value === "object" && !Array.isArray(value);
}
function toDistinctObsDictionary(obsDictionary) {
  return Object.keys(obsDictionary).reduce((acc, key) => ({
    ...acc,
    [key]: obsDictionary[key].pipe(distinctUntilChanged())
  }), {});
}

// src/core/render-event/manager.mjs
import { Observable as Observable2, pipe, ReplaySubject } from "rxjs";
import { distinctUntilChanged as distinctUntilChanged2, switchMap, tap } from "rxjs/operators";
import { untracked } from "@angular/core";
function createRenderEventManager(handlers) {
  const handleRenderEvent = combineRenderEventHandlers(handlers);
  const potentialObservable$ = new ReplaySubject(1);
  return {
    nextPotentialObservable(potentialObservable) {
      potentialObservable$.next(potentialObservable);
    },
    handlePotentialObservableChanges() {
      return potentialObservable$.pipe(distinctUntilChanged2(), switchMapToRenderEvent(), distinctUntilChanged2(renderEventComparator), tap(handleRenderEvent));
    }
  };
}
function switchMapToRenderEvent() {
  return pipe(switchMap(potentialObservable => {
    const observable$ = fromPotentialObservable(potentialObservable);
    let reset = true;
    let synchronous = true;
    return new Observable2(subscriber => {
      const subscription = untracked(() => observable$.subscribe({
        next(value) {
          subscriber.next({
            type: "next",
            value,
            reset,
            synchronous
          });
          reset = false;
        },
        error(error) {
          subscriber.next({
            type: "error",
            error,
            reset,
            synchronous
          });
          reset = false;
        },
        complete() {
          subscriber.next({
            type: "complete",
            reset,
            synchronous
          });
          reset = false;
        }
      }));
      if (reset) {
        subscriber.next({
          type: "suspense",
          reset,
          synchronous: true
        });
        reset = false;
      }
      synchronous = false;
      return subscription;
    });
  }));
}
function renderEventComparator(previous, current) {
  if (previous.type !== current.type || previous.reset !== current.reset) {
    return false;
  }
  if (current.type === "next") {
    return previous.value === current.value;
  }
  if (current.type === "error") {
    return previous.error === current.error;
  }
  return true;
}

// src/let/let.directive.mjs
import { Directive, Input } from "@angular/core";
import { Subscription } from "rxjs";
import * as i03 from "@angular/core";
var LetDirective = class _LetDirective {
  set ngrxLet(potentialObservable) {
    this.renderEventManager.nextPotentialObservable(potentialObservable);
  }
  constructor(mainTemplateRef, viewContainerRef, errorHandler, renderScheduler) {
    this.mainTemplateRef = mainTemplateRef;
    this.viewContainerRef = viewContainerRef;
    this.errorHandler = errorHandler;
    this.renderScheduler = renderScheduler;
    this.isMainViewCreated = false;
    this.isSuspenseViewCreated = false;
    this.viewContext = {
      $implicit: void 0,
      ngrxLet: void 0,
      error: void 0,
      complete: false
    };
    this.renderEventManager = createRenderEventManager({
      suspense: () => {
        this.viewContext.$implicit = void 0;
        this.viewContext.ngrxLet = void 0;
        this.viewContext.error = void 0;
        this.viewContext.complete = false;
        this.renderSuspenseView();
      },
      next: event => {
        this.viewContext.$implicit = event.value;
        this.viewContext.ngrxLet = event.value;
        if (event.reset) {
          this.viewContext.error = void 0;
          this.viewContext.complete = false;
        }
        this.renderMainView(event.synchronous);
      },
      error: event => {
        this.viewContext.error = event.error;
        if (event.reset) {
          this.viewContext.$implicit = void 0;
          this.viewContext.ngrxLet = void 0;
          this.viewContext.complete = false;
        }
        this.renderMainView(event.synchronous);
        this.errorHandler.handleError(event.error);
      },
      complete: event => {
        this.viewContext.complete = true;
        if (event.reset) {
          this.viewContext.$implicit = void 0;
          this.viewContext.ngrxLet = void 0;
          this.viewContext.error = void 0;
        }
        this.renderMainView(event.synchronous);
      }
    });
    this.subscription = new Subscription();
  }
  static ngTemplateContextGuard(dir, ctx) {
    return true;
  }
  ngOnInit() {
    this.subscription.add(this.renderEventManager.handlePotentialObservableChanges().subscribe());
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  renderMainView(isSyncEvent) {
    if (this.isSuspenseViewCreated) {
      this.isSuspenseViewCreated = false;
      this.viewContainerRef.clear();
    }
    if (!this.isMainViewCreated) {
      this.isMainViewCreated = true;
      this.viewContainerRef.createEmbeddedView(this.mainTemplateRef, this.viewContext);
    }
    if (!isSyncEvent) {
      this.renderScheduler.schedule();
    }
  }
  renderSuspenseView() {
    if (this.isMainViewCreated) {
      this.isMainViewCreated = false;
      this.viewContainerRef.clear();
    }
    if (this.suspenseTemplateRef && !this.isSuspenseViewCreated) {
      this.isSuspenseViewCreated = true;
      this.viewContainerRef.createEmbeddedView(this.suspenseTemplateRef);
    }
  }
  static {
    this.ɵfac = function _LetDirective_Factory(t) {
      return new (t || _LetDirective)(i0.ɵɵdirectiveInject(i03.TemplateRef), i0.ɵɵdirectiveInject(i03.ViewContainerRef), i0.ɵɵdirectiveInject(i03.ErrorHandler), i0.ɵɵdirectiveInject(RenderScheduler));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: _LetDirective,
      selectors: [["", "ngrxLet", ""]],
      inputs: {
        ngrxLet: "ngrxLet",
        suspenseTemplateRef: [0, "ngrxLetSuspenseTpl", "suspenseTemplateRef"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([RenderScheduler])]
    });
  }
};
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LetDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: "[ngrxLet]",
      providers: [RenderScheduler]
    }]
  }], () => [{
    type: i03.TemplateRef
  }, {
    type: i03.ViewContainerRef
  }, {
    type: i03.ErrorHandler
  }, {
    type: RenderScheduler
  }], {
    ngrxLet: [{
      type: Input
    }],
    suspenseTemplateRef: [{
      type: Input,
      args: ["ngrxLetSuspenseTpl"]
    }]
  });
})();

// src/push/push.pipe.mjs
import { Pipe } from "@angular/core";
import * as i04 from "@angular/core";
var PushPipe = class _PushPipe {
  constructor(errorHandler) {
    this.errorHandler = errorHandler;
    this.renderScheduler = createRenderScheduler();
    this.renderEventManager = createRenderEventManager({
      suspense: event => this.setRenderedValue(void 0, event.synchronous),
      next: event => this.setRenderedValue(event.value, event.synchronous),
      error: event => {
        if (event.reset) {
          this.setRenderedValue(void 0, event.synchronous);
        }
        this.errorHandler.handleError(event.error);
      },
      complete: event => {
        if (event.reset) {
          this.setRenderedValue(void 0, event.synchronous);
        }
      }
    });
    this.subscription = this.renderEventManager.handlePotentialObservableChanges().subscribe();
  }
  transform(potentialObservable) {
    this.renderEventManager.nextPotentialObservable(potentialObservable);
    return this.renderedValue;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  setRenderedValue(value, isSyncEvent) {
    if (value !== this.renderedValue) {
      this.renderedValue = value;
      if (!isSyncEvent) {
        this.renderScheduler.schedule();
      }
    }
  }
  static {
    this.ɵfac = function _PushPipe_Factory(t) {
      return new (t || _PushPipe)(i0.ɵɵdirectiveInject(i04.ErrorHandler, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "ngrxPush",
      type: _PushPipe,
      pure: false,
      standalone: true
    });
  }
};
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PushPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: "ngrxPush",
      pure: false
    }]
  }], () => [{
    type: i04.ErrorHandler
  }], null);
})();
export { LetDirective, PushPipe, RenderScheduler };
